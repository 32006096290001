const truncateDecimal = (num, decimals=2) => {
    return parseFloat(num.toFixed(decimals));
}

const isNumber = (str) => {
    return !isNaN(parseFloat(str)) && isFinite(str);
}

export {
    truncateDecimal,
    isNumber
}