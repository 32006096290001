const { getLanguage, formatText } = require('core/i18n').i18n;
const isString = require('lodash/lang/isString');
const { CURRENCIES } = require('constants/currencies');
const { PERCENTAGE_SYMBOL } = require('constants/index');

const privateFormatAmount = (
  number,
  decimalLength = 2,
  sectionDelimiter = '.',
  decimalLimiter = ',',
  truncate
) => {
  let parsedNumber = parseFloat(number);

  if (parsedNumber !== number && number) {
    parsedNumber = parseFloat(number.replace(decimalLimiter, sectionDelimiter));
  }
  if (!isNaN(parsedNumber)) {
    const re = '\\d(?=(\\d{' + 3 + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    const num = truncate
      ? parsedNumber.toFixed(Math.max(0, ~~decimalLength))
      : String(parsedNumber);

    return num
      .replace('.', decimalLimiter)
      .replace(new RegExp(re, 'g'), '$&' + (sectionDelimiter || ','));
  } else {
    return '';
  }
};

const getSplittedNumber = (decimalNumber, lang = getLanguage(), decimals = 2) => {
  let decimalMark = '';
  let thousandMark = '';
  const result = {
    whole: '',
    decimals: '',
    decimalMark: '',
  };

  switch (lang) {
    case 'en':
      decimalMark = '.';
      thousandMark = ',';
      break;
    case 'es':
      thousandMark = '.';
      decimalMark = ',';
      break;
    default:
      thousandMark = '.';
      decimalMark = ',';
  }

  result.whole = Math.floor(decimalNumber)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandMark);
  result.decimals = (decimalNumber % 1).toFixed(decimals).substring(2);
  result.decimalMark = decimalMark;

  return result;
};

const decimalsLength = (value, decimals = 2) => {
  const splitedValue = getSplittedNumber(value);
  const part = splitedValue.decimals;
  return part && parseInt(part) > 0 ? decimals : 0;
};

const getPlainAmountObject = amountObject => {
  let plainAmountObject;

  if (amountObject && amountObject.toJS) {
    plainAmountObject = amountObject.toJS();
  } else {
    plainAmountObject = amountObject;
  }

  return plainAmountObject;
};

const formatCurrency = code => {
  return CURRENCIES[code] || code;
};

const formatAmount = (number, lang = getLanguage(), decimals = 2, truncate = true) => {
  switch (lang) {
    case 'en':
      return privateFormatAmount(number, decimals, ',', '.', truncate);
    case 'es':
    default:
      return privateFormatAmount(number, decimals, '.', ',', truncate);
  }
};

const getCurrencyTranslation = currency => {
  return currency ? formatText('currency-' + currency) : '';
};

const formatAmountObject = (amountObject, isPlainAmountObject = false, truncate = true) => {
  const plainAmountObject = isPlainAmountObject ? amountObject : getPlainAmountObject(amountObject);

  const currencySymbol = formatCurrency(plainAmountObject.currency);
  const amount = formatAmount(
    plainAmountObject.amount,
    undefined,
    plainAmountObject.decimalsLength,
    truncate
  );
  const currencyText = getCurrencyTranslation(plainAmountObject.currency);

  return {
    amount,
    currencyText,
    currencySymbol,
    currencyCode: plainAmountObject.currency,
  };
};

const getCurrencyCode = amountObject => {
  return amountObject.currency;
};

const getCurrencyText = amountObject => {
  return formatAmountObject(amountObject).currencyText;
};

const getCurrencySymbol = amountObject => {
  return formatAmountObject(amountObject).currencySymbol;
};

const getFormattedAmount = amountObject => {
  return formatAmountObject(amountObject).amount;
};

const getFormattedString = (amountObject, truncate = true) => {
  return (
    formatAmountObject(amountObject, false, truncate).amount +
    ' ' +
    formatAmountObject(amountObject, false, truncate).currencySymbol
  );
};

const groupByCurrency = (data) => {
  const result = {};

  for (const accountId in data) {
      if (data.hasOwnProperty(accountId)) {
          const account = data[accountId];
          if (account.balance) {
              const { amount, currency } = account.balance;
              if (!result[currency]) {
                  result[currency] = 0;
              }
              result[currency] += amount;
          }
      }
  }

  return Object.entries(result).map(([currency, amount]) => ({ currency, amount }));
};

const formatNumber = number => {
  return !number ? '' : number.toString().replace(/[\.,]/g, m => (m === '.' ? ',' : '.'));
};

const parseSpanishNumberToFloat = number => {
  try {
    const parsedNumber = number.replace(/[.]/g, '').replace(/[,]/g, '.');
    return parseFloat(parsedNumber);
  } catch (e) {
    return number;
  }
};

/**
 *
 * @param {number | string} amount
 * @param {number | undefined} maxDecimals Use undefined to preserve original decimals
 * @param {string | undefined} language
 * @example formatWithoutRound(1.42) // -> "1,42"
 * @example formatWithoutRound(1.42, 3) // -> "1,420"
 */
const formatWithoutRound = (amount, maxDecimals, language = getLanguage()) => {
  const amountNumber = Number(amount);

  if (isNaN(amountNumber)) {
    return amount;
  }

  const amountText = amountNumber + '';

  const thousandSeparator = language === 'en' ? ',' : '.';
  const decimalSeparator = language === 'en' ? '.' : ',';

  const units = amountText.split('.');

  const thousands = units[0];
  const thousandLength = thousands.length;

  const formattedThousands = thousands.split('').reduceRight((result, current, index) => {
    const cursor = index + 1 - thousandLength;

    if (current !== '-' && cursor !== 0 && cursor % 3 == 0) {
      return current + thousandSeparator + result;
    } else {
      return current + result;
    }
  }, '');

  const decimals = units[1] || '';

  if (maxDecimals > 0) {
    const limitedDecimals = decimals.substr(0, maxDecimals);

    if (limitedDecimals.length < maxDecimals) {
      const extraDecimals = Array(maxDecimals - limitedDecimals.length + 1).join('0');
      return formattedThousands + decimalSeparator + limitedDecimals + extraDecimals;
    } else {
      return formattedThousands + decimalSeparator + limitedDecimals;
    }
  } else if (maxDecimals === undefined && decimals) {
    return formattedThousands + decimalSeparator + decimals;
  } else {
    return formattedThousands;
  }
};

const formatInterest = (interest = 0, addPercentageSymbol = true, isDefaultCountry = true) => {
  let interestValue = interest;

  if (isString(interest)) {
    interestValue = interest.includes(',')
      ? parseSpanishNumberToFloat(interest)
      : parseFloat(interest);
  }

  const language = getLanguage();
  let decimalsLength;
  if (language === 'nl' && interestValue % 1 === 0) {
    decimalsLength = 2; // For 'nl', show two decimal places even if the number is whole
  } else {
    decimalsLength = interestValue % 1 ? 2 : 0; // Only show decimals if it has them
  }
  let interestFormated;
  if (isDefaultCountry) {
    interestFormated = formatWithoutRound(interestValue, decimalsLength, 'es');
  } else {
    interestFormated = formatWithoutRound(interestValue, decimalsLength);
  }

  if(addPercentageSymbol) {
    return interestFormated.concat(' ', PERCENTAGE_SYMBOL);
  }
  return interestFormated
};

const formatAmountForService = (amount, prefixWithPlusSign = false) => {
  if (amount === undefined || amount === null) {
    return null;
  }

  const formattedAmount = formatAmount(amount, 'en');
  const plusSign = prefixWithPlusSign ? '+' : '';

  return `${plusSign}${formattedAmount.replace(',', '')}`;
};

const buildAbsoluteFormattedValue = object => {
  const importe = Math.abs(object.getIn(['importeEur', 'importe']));
  const value = formatWithoutRound(importe, 2);
  const currency = formatCurrency(object.getIn(['importeEur', 'moneda']));
  return `${value} ${currency}`;
};

const formatPercentage = (amount, lang, decimals) => {
  if (amount === undefined || amount === null) {
    return '';
  }

  const formattedAmount = formatAmount(amount, lang, decimals);

  return `${formattedAmount} %`;
};

module.exports = {
  decimalsLength,
  getPlainAmountObject,
  formatAmountObject,
  getCurrencyCode,
  getCurrencyText,
  getCurrencyTranslation,
  getCurrencySymbol,
  getFormattedAmount,
  getFormattedString,
  groupByCurrency,
  formatAmount,
  getSplittedNumber,
  formatCurrency,
  formatNumber,
  parseSpanishNumberToFloat,
  formatWithoutRound,
  formatInterest,
  buildAbsoluteFormattedValue,
  formatPercentage,
  formatAmountForService,
};