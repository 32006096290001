// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const { CARD_PAYMENT_METHOD_UNIQUE } = require('constants/index');

const initialState = Immutable.fromJS({
    tooltipFixedPaymentShown: false, // used for tealium
    tooltipInfoShown: false, // used for tealium
    tooltipVariablePaymentShown: false, // used for tealium
    type: '',
    typeIsDirty: false,
    typeIsValid: false,
    value: '',
    valueIsDirty: false,
    valueIsValid: false,
    valid: false,
    success: false
});

function validateStep(state) {
    const type = state.get('type')
    const typeIsValid = !!type;
    const valueIsValid = type === CARD_PAYMENT_METHOD_UNIQUE || !!state.get('value');

    return state.merge({
        typeIsValid,
        valueIsValid,
        typeIsDirty: true,
        valueIsDirty: true,
        valid: typeIsValid && valueIsValid
    });
}

function hirePaymentMethodReducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_PAYMENT_METHOD_VALIDATE_STEP:
            return validateStep(state);

        case actionTypes.HIRE_PAYMENT_METHOD_SET_VALUE:
            return state.merge({
                value: action.payload.value,
                valueIsDirty: !!action.payload.value,
                valueIsValid: !!action.payload.value
            });

        case actionTypes.HIRE_PAYMENT_METHOD_SET_TYPE:
            return state.merge({
                type: action.payload.type,
                typeIsDirty: true,
                typeIsValid: !!action.payload.type,
                value: '',
                valueIsDirty: false,
                valueIsValid: false,
                success: true
            });
        case actionTypes.HIRE_PAYMENT_METHOD_TOOLTIP_SHOWN:
            return state.merge({
                [action.payload.tooltipType]: true
            });

        case actionTypes.HIRE_PAYMENT_METHOD_RESET:
            return initialState;

        default:
            return state;
    }
}

module.exports = hirePaymentMethodReducer;
